import {
  CancelablePromise,
  CancelError,
  Landscape,
  LandscapePartial,
  LandscapeRequired,
  LandscapesService,
  LandscapeTemplate,
  LandscapeTemplatesService,
  Organization,
  PermissionType,
  SocketClientLandscapeSubscribeBody,
  Version,
  VersionRevert
} from '@icepanel/platform-api-client'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import Status from '@/helpers/status'
import * as admin from '@/modules/user/helpers/admin'
import userAnalyticsProfile from '@/modules/user/helpers/analytics-profile'
import { ServerEvents, Socket } from '@/plugins/socket'

import landscapeAnalyticsGroup from './helpers/analytics-group'

export interface ILandscapeModule {
  landscapes: Landscape[]

  landscapeSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, unsubscribe: () => void }>

  landscapeTemplates: LandscapeTemplate[]
  landscapeTemplateThumbnails: Record<string, string>

  landscapesListStatus: Status<{ organizationId: string, promise: CancelablePromise<any> }, { organizationId: string }>
  landscapeTemplatesListStatus: Status
  landscapeCreateStatus: Status<{ templateId: string }>
  landscapeDeleteStatus: Status
  landscapeDuplicateStatus: Status<{ targetOrganizationId?: string }>
  landscapeCopyStatus: Status<{ targetLandscapeId: string }>
}

const name = 'landscape'

@Module({
  name,
  namespaced: true
})
export class LandscapeModule extends VuexModule {
  static namespace = name

  landscapes: Landscape[] = []

  landscapeSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, unsubscribe: () => void }>()

  landscapeTemplates: LandscapeTemplate[] = []
  landscapeTemplateThumbnails: Record<string, string> = {}

  landscapesListStatus = new Status<{ organizationId: string, promise: CancelablePromise<any> }, { organizationId: string }>()
  landscapeTemplatesListStatus = new Status()
  landscapeCreateStatus = new Status<{ templateId: string }>()
  landscapeDeleteStatus = new Status()
  landscapeDuplicateStatus = new Status<{ targetOrganizationId?: string }>()
  landscapeCopyStatus = new Status<{ targetLandscapeId: string }>()

  get landscapePermission () {
    return (landscape?: Landscape): PermissionType | null => {
      if (env.IS_SHARE_LINK) {
        return 'read'
      } else {
        const userId = this.context.rootState.user.user?.id
        const isAdmin = admin.userIds.includes(userId)
        if (landscape?.organizationId) {
          const organization = this.context.rootState.organization.organizations.find((o: Organization) => o.id === landscape.organizationId) as Organization | null
          if (organization && organization.users[userId]) {
            return organization.users[userId].permission
          } else if (isAdmin) {
            return 'read'
          } else {
            return null
          }
        } else if (isAdmin) {
          return 'read'
        } else {
          return null
        }
      }
    }
  }

  get organizationLandscapes () {
    const userOrganizationIds = this.context.rootGetters['organization/userOrganizations']?.map((o: Organization) => o.id) as string[]
    return this.landscapes.filter(o => o.organizationId && userOrganizationIds.includes(o.organizationId))
  }

  get landscapeEvents () {
    return (landscapeId: string) => {
      const versionEvents = (this.context.rootState.version.versions as Version[])
        .filter(o => o.landscapeId === landscapeId)
        .map(o => ({
          ...o,
          type: 'version' as const
        }))

      const versionRevertEvents = (this.context.rootState.version.versionReverts as VersionRevert[])
        .filter(o => o.landscapeId === landscapeId)
        .map(o => ({
          ...o,
          type: 'version-revert' as const
        }))

      return [
        ...versionEvents,
        ...versionRevertEvents
      ].sort((a, b) => {
        const aLatest = a.type === 'version' ? a.tags.includes('latest') : false
        const bLatest = b.type === 'version' ? b.tags.includes('latest') : false
        if (aLatest === bLatest) {
          return a.createdAt > b.createdAt ? -1 : 1
        } else if (aLatest && !bLatest) {
          return -1
        } else if (!aLatest && bLatest) {
          return 1
        } else {
          return 0
        }
      })
    }
  }

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  @Mutation
  setLandscapes (landscapes: Landscape[]) {
    this.landscapes = landscapes
    this.landscapes.sort((a, b) => a.name === b.name ? a.id.localeCompare(b.id) : a.name.localeCompare(b.name))
  }

  @Mutation
  setLandscapeTemplates (landscapeTemplates: LandscapeTemplate[]) {
    this.landscapeTemplates = landscapeTemplates
  }

  @Mutation
  setLandscapeTemplateThumbnail ({ id, url }: { id: string, url: string}) {
    this.landscapeTemplateThumbnails = {
      ...this.landscapeTemplateThumbnails,
      [id]: url
    }
  }

  @Mutation
  setLandscape (landscape: Landscape) {
    this.landscapes = [
      ...this.landscapes?.filter(o => o.id !== landscape.id) || [],
      landscape
    ]
    this.landscapes.sort((a, b) => a.name === b.name ? a.id.localeCompare(b.id) : a.name.localeCompare(b.name))
  }

  @Mutation
  removeLandscape (id: string) {
    this.landscapes = this.landscapes.filter(o => o.id !== id)
  }

  @Mutation
  setLandscapesListStatus (...params: Parameters<typeof this.landscapesListStatus.set>) {
    this.landscapesListStatus.loadingInfo.promise?.cancel()
    this.landscapesListStatus.set(...params)
  }

  @Mutation
  setLandscapeTemplatesListStatus (...params: Parameters<typeof this.landscapeTemplatesListStatus.set>) {
    this.landscapeTemplatesListStatus.set(...params)
  }

  @Mutation
  setLandscapeCreateStatus (...params: Parameters<typeof this.landscapeCreateStatus.set>) {
    this.landscapeCreateStatus.set(...params)
  }

  @Mutation
  setLandscapeDeleteStatus (...params: Parameters<typeof this.landscapeDeleteStatus.set>) {
    this.landscapeDeleteStatus.set(...params)
  }

  @Mutation
  setLandscapeDuplicateStatus (...params: Parameters<typeof this.landscapeDuplicateStatus.set>) {
    this.landscapeDuplicateStatus.set(...params)
  }

  @Mutation
  setLandscapeCopyStatus (...params: Parameters<typeof this.landscapeCopyStatus.set>) {
    this.landscapeCopyStatus.set(...params)
  }

  @Mutation
  setLandscapeSubscriptionStatus (...params: Parameters<typeof this.landscapeSubscriptionStatus.set>) {
    this.landscapeSubscriptionStatus.set(...params)
  }

  @Mutation
  landscapeUnsubscribe () {
    this.landscapeSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.landscapeSubscriptionStatus.successInfo?.unsubscribe?.()
    this.landscapeSubscriptionStatus.set(Status.idle())
  }

  @Action({ rawError: true })
  async landscapeSubscribe (body: SocketClientLandscapeSubscribeBody & { reconnect: boolean }) {
    this.landscapeSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.landscapeSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setLandscapeSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect
    }))

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('landscape-subscribe', {
        landscapeId: body.landscapeId
      }, (err, reply) => {
        if (reply) {
          const landscapeListener: ServerEvents['landscape'] = async ({ landscape, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setLandscape', landscape)

              if (landscape?.organizationId && !this.context.rootState.organization.organizations.some((o: Organization) => o.id === landscape?.organizationId)) {
                await this.context.dispatch('organization/organizationFind', landscape.organizationId, { root: true })
              }

              this.context.commit('setLandscapeSubscriptionStatus', Status.success({
                landscapeId: body.landscapeId,
                subscriptionId: reply.subscriptionId,
                unsubscribe
              }))

              this.context.dispatch('socket/checkSocket', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('landscape', landscapeListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('landscape-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('landscapes unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('landscape', landscapeListener)

          this.context.commit('setLandscapeSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe
          }))

          resolve()
        } else {
          const error = err || 'Landscape subscription not provided'
          this.context.commit('setLandscapeSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async landscapeFind ({ organizationId, landscapeId }: { organizationId: string, landscapeId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { landscape } = await LandscapesService.landscapeFind(authorization, organizationId, landscapeId)
    this.context.commit('setLandscape', landscape)
    return landscape
  }

  @Action({ rawError: true })
  async landscapesList (organizationId: string) {
    try {
      this.context.commit('setLandscapesListStatus', Status.loading({
        organizationId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const promise = LandscapesService.landscapesList(authorization, organizationId)
      this.context.commit('setLandscapesListStatus', Status.loading({
        organizationId,
        promise
      }))

      const { landscapes } = await promise
      this.context.commit('setLandscapes', landscapes)

      landscapes.forEach(o => {
        landscapeAnalyticsGroup.set(o.id, {
          createdAt: o.createdAt,
          name: o.name,
          organizationId: [o.organizationId]
        })
      })

      userAnalyticsProfile.set({
        landscapeCount: this.organizationLandscapes.length,
        landscapeId: this.organizationLandscapes.map(o => o.id)
      })

      this.context.commit('setLandscapesListStatus', Status.success({
        organizationId
      }))

      return landscapes
    } catch (err: any) {
      if (!(err instanceof CancelError)) {
        const message = err.body?.message || err.message
        this.context.commit('setLandscapesListStatus', Status.error(message))
        this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
        throw err
      }
    }
  }

  @Action({ rawError: true })
  async landscapeCreate ({ organizationId, name, structurizrWorkspace, backstageEntities, templateId }: { organizationId: string } & Parameters<typeof LandscapesService.landscapeCreate>[2]) {
    try {
      this.context.commit('setLandscapeCreateStatus', Status.loading({
        templateId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { landscape, version } = await LandscapesService.landscapeCreate(authorization, organizationId, {
        backstageEntities,
        name,
        structurizrWorkspace,
        templateId
      })
      this.context.commit('setLandscape', landscape)

      landscapeAnalyticsGroup.set(landscape.id, {
        appUrl: `${env.APP_URL}/landscapes/${landscape.id}`,
        createdAt: landscape.createdAt,
        name: landscape.name,
        organizationId: [landscape.organizationId]
      })

      userAnalyticsProfile.union('landscapeId', [landscape.id])
      userAnalyticsProfile.increment('landscapeCount', 1)

      this.context.commit('setLandscapeCreateStatus', Status.success())

      return {
        landscape,
        version
      }
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setLandscapeCreateStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async landscapeUpdate ({ organizationId, landscapeId, update }: { organizationId: string, landscapeId: string, update: LandscapePartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { landscape } = await LandscapesService.landscapeUpdate(authorization, organizationId, landscapeId, update)
    this.context.commit('setLandscape', landscape)

    landscapeAnalyticsGroup.set(landscape.id, {
      name: landscape.name,
      organizationId: [landscape.organizationId]
    })

    return landscape
  }

  @Action({ rawError: true })
  async landscapeDuplicate ({ organizationId, landscapeId, targetOrganizationId, name }: { organizationId: string, landscapeId: string, targetOrganizationId?: string } & LandscapeRequired) {
    try {
      this.context.commit('setLandscapeDuplicateStatus', Status.loading({
        targetOrganizationId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { landscape } = await LandscapesService.landscapeDuplicate(authorization, organizationId, landscapeId, { name }, targetOrganizationId)
      this.context.commit('setLandscape', landscape)

      landscapeAnalyticsGroup.set(landscape.id, {
        appUrl: `${env.APP_URL}/landscapes/${landscape.id}`,
        createdAt: landscape.createdAt,
        name: landscape.name,
        organizationId: [landscape.organizationId]
      })

      userAnalyticsProfile.union('landscapeId', [landscape.id])
      userAnalyticsProfile.increment('landscapeCount', 1)

      this.context.commit('setLandscapeDuplicateStatus', Status.success())

      return landscape
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setLandscapeDuplicateStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async landscapeCopy ({ organizationId, landscapeId, targetLandscapeId }: { organizationId: string, landscapeId: string, targetLandscapeId: string }) {
    try {
      this.context.commit('setLandscapeCopyStatus', Status.loading({
        targetLandscapeId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      await LandscapesService.landscapeCopy(authorization, organizationId, landscapeId, targetLandscapeId)
      this.context.commit('setLandscapeCopyStatus', Status.success())
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setLandscapeCopyStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async landscapeDelete ({ organizationId, landscapeId }: { organizationId: string, landscapeId: string }) {
    try {
      this.context.commit('setLandscapeDeleteStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      await LandscapesService.landscapeDelete(authorization, organizationId, landscapeId)
      this.context.commit('removeLandscape', landscapeId)

      landscapeAnalyticsGroup.delete(landscapeId)

      userAnalyticsProfile.remove('landscapeId', landscapeId)
      userAnalyticsProfile.increment('landscapeCount', -1)

      this.context.commit('setLandscapeDeleteStatus', Status.success())
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setLandscapeDeleteStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async landscapeTemplatesList () {
    try {
      this.context.commit('setLandscapeTemplatesListStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { landscapeTemplates } = await LandscapeTemplatesService.landscapeTemplatesList(authorization)
      this.context.commit('setLandscapeTemplates', landscapeTemplates)

      this.context.commit('setLandscapeTemplatesListStatus', Status.success())

      return landscapeTemplates
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setLandscapeTemplatesListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async landscapeTemplateThumbnailsPrimaryGet (landscapeTemplateId: string) {
    try {
      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { thumbnail } = await LandscapeTemplatesService.landscapeTemplateThumbnailsPrimaryGet(authorization, landscapeTemplateId)
      this.context.commit('setLandscapeTemplateThumbnail', {
        id: landscapeTemplateId,
        url: thumbnail.url
      })
    } catch (err: any) {}
  }
}
